import { render, staticRenderFns } from "./EmailCertification.vue?vue&type=template&id=741f4b78"
import script from "./EmailCertification.vue?vue&type=script&lang=js"
export * from "./EmailCertification.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports