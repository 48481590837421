<template>
    <div>
        <!-- 본문 -->
        <div
            class="py-16"
        >
            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':400"
            >
                <!-- 로고 & 문구 -->
                <div
                    class="d-flex mb-2 mx-auto"
                >
                    <v-img
                        height="72"
                        width="148"
                        contain
                        src="@/assets/logo/default.png"
                    ></v-img>
                </div>
                <p
                    class="mb-6 text-subtitle-1 font-weight-medium grey--text text--darken-2"
                >
                    이메일 인증
                </p>

                <!-- 인증 실패시 -->
                <v-sheet
                    v-if="!cert"
                    class="text-center"
                >
                    <!-- 아이콘 -->
                    <v-icon
                        size="80"
                        color="red"
                        class="ml-3"
                    >
                        mdi-email-this.$globalSnackbar.show-outline
                    </v-icon>

                    <!-- 문구 -->
                    <p class="my-4 font-weight-bold grey--text">
                        이메일 인증코드가 만료되었거나<br/>
                        이미 인증을 완료된 계정입니다.
                    </p>

                    <p class="text-body-2 font-weight-bold red--text">
                        반복될 시 고객센터로 문의바랍니다.
                    </p>
                </v-sheet>

                <!-- 인증 성공시 -->
                <v-sheet
                    v-if="cert"
                    class="text-center"
                >
                    <!-- 아이콘 -->
                    <v-icon
                        size="80"
                        color="primary"
                    >
                        mdi-email-check-outline
                    </v-icon>

                    <!-- 문구 -->
                    <p class="mt-4 font-weight-bold grey--text text--darken-2">
                        이메일 인증이 완료되었습니다.<br/>
                        회원가입을 축하합니다.
                    </p>
                </v-sheet>

                <!-- 버튼 -->
                <div class="d-flex justify-center mt-10">
                    <v-btn
                        width="240"
                        class="font-weight-bold"
                        color="primary"
                        dark
                        large
                        depressed
                        to="/auth/login"
                    >
                        로그인 하러가기
                    </v-btn>
                </div>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        cert: false
    }),

    mounted(){
        // 인증값 확인하기
        this.$http.post('/api/etc/email_certification/select/check', {
            params: {
                user_id: this.$route.query.user_id,
                serial: this.$route.query.serial
            }
        }).then((res) => {
            if(res.data.length){
                // 인증완료 처리하기
                this.$http.post('/api/user/update/status', {
                    params: {
                        status: "인증완료",
                        user_id: this.$route.query.user_id,
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.cert = true

                        // 인증값 삭제하기
                        this.$http.post('/api/etc/email_certification/delete', {
                            params: {
                                user_id: this.$route.query.user_id,
                                serial: this.$route.query.serial
                            }
                        })
                    }
                })
            }
        })
    }
}
</script>